exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-revenue-dividend-share-js": () => import("./../../../src/pages/revenue-dividend-share.js" /* webpackChunkName: "component---src-pages-revenue-dividend-share-js" */),
  "component---src-pages-staking-js": () => import("./../../../src/pages/staking.js" /* webpackChunkName: "component---src-pages-staking-js" */),
  "component---src-pages-wheel-of-dragon-js": () => import("./../../../src/pages/wheel-of-dragon.js" /* webpackChunkName: "component---src-pages-wheel-of-dragon-js" */),
  "component---src-pages-whitepaper-js": () => import("./../../../src/pages/whitepaper.js" /* webpackChunkName: "component---src-pages-whitepaper-js" */)
}

