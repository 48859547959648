module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://dev.s8b.888bits.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"888bits","short_name":"888bits","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icons":[{"src":"/favicons/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"any maskable"},{"src":"/favicons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"any maskable"},{"src":"/favicons/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"any maskable"},{"src":"/favicons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"any maskable"},{"src":"/favicons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"/favicons/icon-256x256.png","sizes":"256x256","type":"image/png","purpose":"any maskable"},{"src":"/favicons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"any maskable"},{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"E:\\xampp_8_0_7\\htdocs\\overgame\\react-client-2\\gatsby4\\presale-888bits/src/intl","languages":["en"],"defaultLanguage":"en","redirect":false,"redirectDefaultLanguageToRoot":true,"ignoredPaths":[],"fallbackLanguage":"en"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*","/staking/*","/revenue-dividend-share/*","/wheel-of-dragon/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
